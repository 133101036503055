

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65) !important;
}

.page-wrapper {
  /* background-color: #f9f9f9; */
  /* background-color: #f8f2eb; */
}

p{
  font-family: "Lato";
  font-size: 18px;
}