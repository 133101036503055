.category-label .text-label {
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  background-color: #17733a;
  color: white;
  // border-radius: 15px;
  padding: 6px 10px;
  width: 100%;
  margin-top: -1px;
  text-align: center;
  opacity: 0.95;
  font-weight: 500;
}


// @media only screen 
//   and (min-device-width: 414px) 
//   and (max-device-width: 736px) 
//   and (-webkit-min-device-pixel-ratio: 3)
//   and (orientation: portrait) { 
//     .category-label .text-label {
//       font-family: "Lato";
//     }
// }





.category-label {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
}

.not-available{
  opacity: 0.85;
  width: 100%;
  position: absolute;
  background-color: green;
  top: 40%;
  left:50%;
  transform: translate(-50%, 50%);
}


@media screen and (max-width: 650px)
{ 
  .not-available{
    width: 100%;
    position: absolute;
    background-color: green;
    opacity: 0.85;
    top: 30%;
    left:50%;
    transform: translate(-50%, 50%);
    font-size: 12px;
    padding: 3px 5px;
    font-family: "Montserrat";
  }

}




.category-label:hover{
  cursor: pointer;
}

.category-label.noclickable{
  cursor: default;
}

.category-label:hover {
  opacity: 0.9;
}

.catalogo {
  margin-top: 80px;
}


.category-wrapper-new {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden;

}

.category-wrapper-new:hover{



}