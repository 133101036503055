.subtitle-text {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 1.2rem;
    font-family: "Montserrat";
    color: rgba(0, 0, 0, 0.65);
  }
  
  .subtitle {
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  
  .title-text {
    font-family: "Montserrat", sans-serif;
    color: #17733a;
  }
  
  .item-title {
    // margin-bottom: 3px;
    display: flex;
    justify-content: center;
  }
  
  .ToggleGroup {
    margin-left: 20px;
  }
  
  .item-vaso-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .item-vaso-image:hover {
    opacity: 0.7;
  }
  
  .item-vaso-wrapper {
    width: 100%;
    height: auto;
    margin: 20px 5px;
    display: flex;
    flex-direction: column;
    border:  1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  }
  
  @media screen and (max-width: 600px) {
    .item-vaso-wrapper {
        margin: 10px 0px;
        
    }
    // .item-image{

    // }
    .subtitle-text {
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 0.95rem;
    }
  
  }
  
  .item-vaso-wrapper:hover {
    // transition: all .3s ease;
    // transform: scale(.92);
    cursor: pointer;
    // box-shadow: 0 0 15px 5px rgba(200,200,200,.4);
  
  }
  
  
  @media screen and (min-width: 1200px) {
    .item-vaso-image{

    }
  }
  
  
  @media screen and (min-width: 800px) {
    // .item-vaso-wrapper{
    //   margin: 20px 15px;
    //   width: 100%;
    //   height: auto;
    // }
  
    .item-image{
      // min-height: 500px;
      // height: 280px;
    }
  }
  
  
  @media screen and (min-width: 1600px) {
    .item-vaso-image{
      // min-height: 500px;
      // height: 280px;
    }
  }
  