
.menu-laterale {
    // background-color: #f7f7f7;
    width: 100%;
    margin: 10px 20px;
    padding: 10px 0px;
    float: left;
    margin-top: 50px;
    line-height: 1.7;
  }
  
  .menu-laterale-titolo {
    font-family: "Catamaran";
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
    color: orange;
    
  }
  
  .menu-laterale-voce {
    padding-left: 10px;
    font-family: "Catamaran";
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.2;
  }
  

  .menu-laterale-voce:hover{
  }
