.itemWrapper {
  display: flex;
  flex-direction: column;
  border: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
  overflow: hidden;
}

.img:hover {
  opacity: 0.9;
}

.item-carousel-home:hover{
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .itemWrapper {
    min-height: 485px;
  }

  .img {
    max-height: 336px;
  }
}

.img-center {
  position: relative;
  left: 30px;
  top: 5px;
  max-height: 327px;
}


.img-center:hover{
  opacity: 0.9;
  transform: scale(1.05);
}


.owl-prev {
  width: 25px;
  height: 100px;
  position: absolute;
  top: 20%;
  left: -25px;
  display: block !important;
  border:0px solid black;
  span{
    font-size: 3rem;
  }
}

.owl-next {
  width: 25px;
  height: 100px;
  position: absolute;
  top: 20%;
  right: -25px;
  display: block !important;
  border:0px solid black;
  span{
    font-size: 3rem;
  }
}
