.navbar{
    background-color: white !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.24);
}


.dropdown-menu.show{
    min-width: 2rem;
}

.nav-link{
    font-size: 17px !important;
    // font-family: 'Ubuntu', sans-serif !important;
    // font-family: 'Nunito', sans-serif !important;
    // font-family: 'Questrial', sans-serif !important;
    font-family: 'Catamaran', sans-serif !important;
    font-weight: 500;




}






@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .logo-img{
        padding: 0;
        margin-left: 0;
        width: 160px;
        height: auto;
        margin-left : 5px !important;
    }
    .navbar-brand{
        padding-bottom: .2125rem !important;
    }
}


.logo-img{
    margin-left: 20px;
    padding: 2px;
}