.plastica-image{
    width: 350px;
    height: 350px;
}


@media only screen and (max-width: 650px) {
    .plastica-image{
        width: 320px;
        height: 320px;
    }
}