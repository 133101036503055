$sub-color: grey;
$main-color: #17733a;

@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
  color: $main-color;
}

.group {
  position: relative;
  //prima 45px
  margin: 25px 0;

  .form-input {
    -webkit-appearance: none;

    width: 100%;
    max-width: 425px;

    /* Fix for Safari/iOS date fields */
    min-height: calc(0.9em + (0.8em * 2) + 0.6em);

    padding: 0.8em;
    font-size: 0.9em;
    font-family: "Source Sans Pro", sans-serif;

    outline: none;
    border: 1px solid #b6b6b6;
    border-radius: 4px;
    background: none;
    transition: background 0.25s, border-color 0.25s, color 0.25s;


    &:focus {
      // background: none;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }
  }

  input[type="password"] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
    font-family: "Roboto", sans-serif;

    &.shrink {
      @include shrinkLabel();
    }
  }
}

label {
  color: #17733a;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
