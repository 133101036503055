.VasoItemWrapper {
  // display: flex;
  // flex-direction: column;
  border: rgba(0, 0, 0, 0.7);

  margin: 10px 0px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
  // padding: 10px;
  overflow: hidden;
  height: auto;
  object-fit: cover;
  // border: solid 0.5px rgba(0,0,0,0.1);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.VasoItemWrapper:hover {
  cursor: pointer;
  border: solid 0.5px orange;
  box-shadow: 0 2px 8px 0 orange;
}

.img:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

