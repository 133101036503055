.img-responsive-carousel {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.img-responsive {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.img-responsive:hover {
  opacity: 0.9;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.myslider {
  margin-bottom: 30px;
}
