.pre-footer {
  width: 100%;
  background-color: #9abf8f;
  padding: 40px 50px;
}

.iconcenter {
  padding-top: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.para {
  color: rgb(23, 115, 58);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding: 0px 20px;
}
