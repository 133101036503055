.iconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 50px;
  font-family: "Poppins";
}

.iconPadding {
  flex-direction: row;
  padding: 30px 20px;
}

.detailWrap {
  padding: 0;
}

.iconPadding-last {
  padding: 0 20px;
}

.span-detail {
  font-weight: bold;
}

.myCard {
  margin-top: 10px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 127, 67, 0.15) !important;
}

.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.MuiToggleButtonGroup-groupedHorizontal {
  font-family: "Poppins";
}

button:focus {
  outline: none !important;
}

.MuiToggleButton-root:focus {
  outline: 5px auto green !important;
}





.item-detail-page {
  margin-top: 90px;
}

.centerItems {
  text-align: center;
  // display: flex;
  // justify-items: center;
}

.btn-vaso {
  background-color: white !important;
  color: #17733a !important;
}

.btn-vaso:focus {
  background-color: #17733a !important;
  color: white !important;
  box-shadow: none !important;
}

.Field {
  margin: 20px 0;

  .title{
    font-size: 1rem !important;
  }
  .dettaglio{
    font-size: 0.95rem;
  }
  
}

.Field.first{
    margin: 8px 0;
}



.detailWrap .title {
  font-size: 1rem;
  font-family: "Catamaran";
  line-height: 1.2;
  letter-spacing: 0.5px;
  font-weight: 700;
  
}



.detailWrap p{
  margin-bottom: 1px;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 500px) {

  .contattaci-text{
    font-size: 1rem;
  }


  .detailWrap .title {
    font-size: 1rem;

   }
  .detailWrap .title.larger {
    font-size: 1.2rem;
  }
  .btn-group {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }
  .btn-vaso {
    margin: 10px;
  }
}

.info {
  border-radius: 5px;
}

.left-img {
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
}

.contattaci-text{
  padding: 2px 4px;
  border-radius: 3px;
  font-family: "Roboto Condensed";
  font-size: 1.5rem;
  font-weight: 700;
  // color: rgba(0,0,0,0.6);
  color: white;
  background-color: #ccc4ac;
// text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
//                  0px -5px 35px rgba(255,255,255,0.3);
}
.detailWrap.nome{
  font-size: 2.5rem;
}

.main-img{
}



@media only screen and (max-width: 650px) {
  .item-detail-page {
    margin-top: 55px;
  }

  .choose-pot{
    margin-top: 4px !important;
    font-size: 20px !important;
    margin-bottom: 4px !important;
  }

  .title.larger.nocenter.nome{
    margin-top: 16px;
    font-size: 26px !important;
    margin-bottom: 4px !important;
  }

  .Field.first{
    margin: 2px 0;
}


  .Field {
    margin: 16px 0;
  
    .title{
      font-size: 1rem !important;
    }
    .dettaglio{
      font-size: 0.95rem;
    }
    
  }

  .custom-tooltip{
    font-size: 24px !important;
  }

  .MuiTooltip-tooltip{
    font-size: 24px;
  }


  .contattaci-text{
    font-size: 1rem;
  }
  .main-img{
    margin: 0 20%;
  }
  
  .detailWrap.nome{
    font-size: 1.5rem;
  }

  .olea-image{
    height: 225px !important;
    width: 150px !important;
  }
  .olea-image-zoomin{
    height: 337px !important;
    width: 225px !important;
  }
}

.olea-image{
  height: 450px;
  width: 300px;
}

.olea-image-zoomin{
  height: 675px;
  width: 450px;
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    .VasoItemWrapper{
      width: 80px;
      height: 80px;
    }
    .carousel-container{
      padding: 0 20px !important;
    }
    .title.larger.nocenter.nome{
      margin-top: 16px;
      font-size: 26px !important;
      margin-bottom: 4px !important;
    }

}

.choose-pot{
  margin-top: 12px;
  font-size: 1.5rem;
  font-family: "Lato";
  // letter-spacing: 0.5;
  color:
    rgba(0, 0, 0, 0.5);
  text-align: center;
}

.VasoItemWrapper{
  width: 120px,
  height 120px,
}