.homepage {
  padding: 0% 0%;
  margin-top: 77px;
}

.cont-uno {
  margin: 50px 0;
  font-size: 16px;
}

.wrap {
  display: flex;
  justify-content: center;
}

.my-cont {
  margin: 10px 10px;
}

.title {
  font-family: "Poppins", sans-serif;
  color: #17733a;
  text-align: center;
}

.top {
  margin-top: 10px;
}

.title.nocenter {
  text-align: left;
}

@media only screen and (max-width : 640px) {
  
  .homepage{
    margin-top: 65px;
  }
  .video-box{
    width: 100%;
    height: 320px;
  }
  .home-card{
    margin-bottom: 16px;
  }
  .card-div{
    padding-top: 0px !important;
  }
  .card p{
    margin-bottom: 0!important;
    font-size: 1rem;
  }

  .home-title{
      color: white;
      font-weight: bolder;
      font-size: 1rem;
  
  }
}

@media only screen and (min-width : 1024px) {
  .home-title{
    font-size: 2rem;
    color: white;
    font-weight: bolder;
  }
}








.card-div{
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}



h1 {
  overflow: hidden;
  text-align: center;
}




h1:before,
h1:after {
  background-color: #17733a;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h1:before {
  right: 0.5em;
  margin-left: -50%;
}

h1:after {
  left: 0.5em;
  margin-right: -50%;
}


.title.bold {
  font-weight: 700;
}

.title-container {
  margin: 20px 0px;
}

p {
  /* font-family: "Roboto", sans-serif; */
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
}

.first-section {
  margin: 50px 0px;
}

.logo-img {
  height: 50px;
}

.big-title{
  font-family: 'Courgette', cursive;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .subtitle{
      padding: 2% 10%;
    }
    .big-title{
      font-size: 1.4rem !important;
    }
    .section-two{
      padding: 5px 5% !important;
    }
}

.dropdown-item.active,
.dropdown-item:active {
  color: rgba(0, 0, 0, 0.7) !important;
  text-decoration: none;
  background-color: white !important;
}

.section-2 {
  background-color: #6a766a;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.title {
  margin-bottom: 25px;
}

.nav-link {
  color: #17733a !important;
  text-transform: uppercase;
}

.nav-link:hover {
  opacity: 0.3;
}


 .par-bg{
  opacity: 0.85;
} 




@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
   {
    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    }
    .videoWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}
