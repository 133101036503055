.vaso {
  width: 220px;
  height: auto;
  border-radius: 25px;
  margin: 25px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
}

.vaso.plastica {
  background-color: none;
  
}


@media only screen and (max-width: 800px) {
  .vaso {
    width: 75px;
    height: 75px;
    margin: 0;
    padding: 5px;
  }

  .vaso-wrapper .title {
    font-size: 12px;
  }
}

.vaso:hover {
  transform: scale(1.1);
  opacity: 0.8;
  cursor: pointer;
}


.withMargin {
  margin: 40px 0;
}

.vaso-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vasi-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}