.title.carouselTitle {
  font-size: 24px;
  font-family: "Lato", sans-serif;
}

.carouselText {
  background-color: rgba(255, 255, 0, 0.15);
  padding: 0 10%;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .title.carouselTitle {
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
    }
}
