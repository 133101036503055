.my-text-area {
  border-radius: 15px;
  padding: 3% 5%;
  margin-bottom: 10px;
}

.form-input:focus {
  outline: none !important;
  
}

.form-input::placeholder {
  color: #bbbbbb;
}
