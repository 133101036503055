.plastica-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.color-icon:hover {
  transform: scale(1.1);
}

.item-button:hover {
  cursor: pointer;
}
