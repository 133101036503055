hr.solid {
  border-top: 1px solid #17733a;
  width: 100%;
  opacity: 0.5;
}

p {
  // text-align: justify;
}

.azienda {
  margin-top: 95px;
}

.azienda p{
  line-height: 1.7;
  
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.slick-slide {
	padding: 0 8px;
	box-sizing: border-box;
}

