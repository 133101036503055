
a {
    text-decoration: none;
  }

  @media screen and (max-width: 600px) {
  .cards{
      padding: 0px 30px !important;
  }
  }

