.img-fluid {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.produzione {
  margin-top: 100px;
}


.produzione p{
  line-height: 1.7;
}

.carousel-item > img { 
  width: 100%; 
}