button:focus {
  outline: 0 !important;
}

.custom-button {
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: 	#17733a;
  color: white;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: bolder;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: white;
    color: 	#4c543c;
    border: 1px solid 	#17733a;
  }
}
