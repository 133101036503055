.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin: 5px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.down:hover {
  cursor: pointer;
}

.title-wrap {
  display: flex;
  justify-content: center;
}

.no-margin {
  margin-bottom: 0 !important;
}

.title {
  font-size: 1rem;
  margin-top: 1px;
}

.title.larger {
  font-size: 1.5rem;
}



@media only screen and (max-width: 500px) {
  .title.larger {
    font-size: 1.2rem;
  }
}


.logo-collapse {
  position: relative;
  top: 4px;
  margin-top: 2px;
  width: 1rem;
  height: 1rem;
  float: left;
  margin-right: 20px;
}


div-wrap {
  display: flex;
  align-items: center;
}


.double {
  width: 1.5rem;
  position: relative;
  bottom: 10px;
  margin-right: 10px;
}