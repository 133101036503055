.subtitle-text {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Montserrat";
  color: rgba(0, 0, 0, 0.65);
}

.subtitle {
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.title-text {
  font-family: "Montserrat", sans-serif;
  color: #17733a;
}

.item-title {
  // margin-bottom: 3px;
  display: flex;
  justify-content: center;
}

.ToggleGroup {
  margin-left: 20px;
}

.item-image {
  height: 300px;
  width: 200px;
  margin-bottom: 10px;
}

.item-image:hover {
  opacity: 0.7;
}

.item-wrapper {
  width: 200px;
  height: 380px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border:  1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
}

@media screen and (max-width: 600px) {
  .item-wrapper {
    width: 100%;
    height: auto;
    margin: 8px 5px;
  }
  .item-image{
    width: 100%;
    height: auto;
  }
  .subtitle-text {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 0.95rem;
  }

}

.item-wrapper:hover {
  // transition: all .3s ease;
  // transform: scale(.92);
  cursor: pointer;
  // box-shadow: 0 0 15px 5px rgba(200,200,200,.4);

}


@media screen and (min-width: 1200px) {
  .item-image{
    // min-height: 500px;
    // height: 280px;
  }
}


@media screen and (min-width: 800px) {
  .item-wrapper{
    margin: 20px 0;
    width: 200px;
    height: 380px;
  }

  .item-image{
    // min-height: 500px;
    // height: 280px;
  }
}


@media screen and (min-width: 1600px) {
  .item-image{
    // min-height: 500px;
    // height: 280px;
  }
}
