@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
 .certLogo{
   width: 90px !important;
   height: 90px !important;
 }
 .certLogoMano{
   width: 90px !important;
   height: 65px !important;
 }
}



.certLogo{
  width: 200px;
  height: 180px;
}

.certLogoMano{
  width: 200px;
  height: 130px;
}