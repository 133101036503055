.contatti {
  margin-top: 100px;
}


@media only screen and (max-width: 600px) {

.social-link{
 font-size: 14px;
}
}