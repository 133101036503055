.img-responsive-carousel {
  width: 100%;
  height: 600px;
  // object-fit: cover;
}

.img-responsive-carousel-rounded {
  border-radius: 25px;
  width: 100%;
  height: auto;
}

.img-responsive {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.img-responsive.no-hover:hover {
  opacity: 1.0;
}




.img-responsive.clickable:hover {
  cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.myslider {
  margin-bottom: 30px;
}